import React, { useState, useEffect } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import './countdown.css'

function Countdown() {
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-09-09T17:30:00`) - +new Date();
    
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        días: Math.floor(difference / (1000 * 60 * 60 * 24)),
        horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutos: Math.floor((difference / 1000 / 60) % 60),
        segundos: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval, i) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={i} className="white-text">
        <h4>
          {timeLeft[interval]} {interval}{" "}
        </h4>
      </span>
    );
  });

  return (
    <div className="countdown-wrapper">
      <video 
        src="/video.MP4"
        onError={(e)=>{e.target.onerror = null; e.target.poster="/foto.jpeg"}}
        autoPlay 
        loop 
        muted 
        playsInline 
        className="background-video"
      />
      <div className="countdown">
        <h1 className="white-text">Marina y <br/> Juan Alberto </h1>
        {timerComponents.length ? (
          <div className="card-content white-text">
            <div className='contador'>
              {timerComponents}
            </div>
            
          </div>
        ) : (
          <span className="white-text">¡Hora de comenzar!</span>
        )}
      </div>
    </div>
  );
}

export default Countdown;