import React from 'react';
import "./footer.css";


function Footer() {
  return (
    <footer className='footer' style={footerStyle}>
      <p>Hecho con amor por <br/> Marina y Juan Alberto ❤️</p>
    </footer>
  );
}

const footerStyle = {
  backgroundColor: '#f1f1f1',
  padding: '20px',
  textAlign: 'center',
};

export default Footer;