import React, { useEffect } from 'react';
import './rsvpform.css'

function RSVPForm() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <button
      className='rsvp'
      data-tf-popup="DvrDmHRz"
      data-tf-opacity="100"
      data-tf-size="100"
      data-tf-iframe-props="title=¡Confirma tu asistencia próximamente en comenzar!"
      data-tf-transitive-search-params
      data-tf-medium="snippet"
      style={{
        all: 'unset', 
        fontFamily: 'Helvetica,Arial,sans-serif',
        display: 'inline-block',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        backgroundColor: '#F1ECE2',
        color: '#000',
        fontSize: '20px',
        borderRadius: '25px',
        padding: '0 33px',
        fontWeight: 'bold',
        height: '50px',
        cursor: 'pointer',
        lineHeight: '50px',
        textAlign: 'center',
        margin: '0',
        textDecoration: 'none'
      }}
    >
      Confirmar asistencia
    </button>
  );
}

export default RSVPForm;

